import React,{useState} from 'react'
import { Add, CheckCircle, Edit, Sync } from '@mui/icons-material';
import api from "../../../../api/api";
import withAlert from '../../../../hoc/withAlert';

function TrackFile({deviceId, AppAlert, trackDetails,trackNo ,publishTrack,onSuccess}) {

    const [uploaded ,setUploadStatus] =  useState(false);
    const [imagePath,setImagePath] = useState(null);
    const [base64,setBase64] = useState(null);

    const selectFile = (e) => {
        let data = new FormData();
        data.append('tmpFile', e.target.files[0]);
        data.append('trackId',trackDetails.id);
        data.append('trackNo',trackNo);
        data.append('deviceId',deviceId);
        setUploadStatus(false);
        api.post("upload/track",data).then( (res)=>{
            if(!res.data.error){
                AppAlert(res.data.msg);
                onSuccess();
            } else {
                AppAlert(res.data.msg,"error");
            }
        }).catch( (error) => {
            AppAlert(error.msg,"error")
        }).finally(()=>{
            setUploadStatus(false);
            
        })
    }

    

    const getBase64 = (e) => {
        let file = e.target.files[0]
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = function () {
          let _base64 = reader.result;
          console.log(_base64);
          setBase64(_base64);
        };
        reader.onerror = function (error) {
          console.log("Error: ", error);
        };
    };


    return (
        <div class="d-flex justify-content-between">
                <div className="form-group">
                    <input 
                        type="file"
                        onChange = {
                            (e)=>{
                                //getBase64(e);
                                selectFile(e);
                            }
                        }
                        accept={".mp3"}
                        id={"track_"+trackDetails.id}
                        className="d-none"
                    />
                    {trackDetails.url == null ?
                        <label htmlFor={"track_"+trackDetails.id} className="btn btn-success" > <Add /> Audio Track </label>
                        :
                        <label htmlFor={"track_"+trackDetails.id} className="btn btn-warning" > <Edit/> Audio Track </label>
                    }
                </div>

                    {base64 &&
                        <>
                        <audio controls>
                            <source src={base64} />
                        </audio>
                        <button className="btn btn-warning" onClick={ () => publishTrack(base64,trackNo)}><Sync/></button>
                        </>
                    }

                {trackDetails.url !== null && 
                    <>
                        <audio controls>
                            <source src={trackDetails.url} />
                        </audio>
                        {trackDetails.syncStatus == 0 ? 
                            <button className="btn btn-warning" onClick={ () => publishTrack(trackDetails.url,trackNo)}><Sync/></button>
                            :
                            <span className="text-success"><CheckCircle/></span>
                        }
                    </>
                }
        </div>
    )
}

export default withAlert(TrackFile)