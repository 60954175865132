import { Cancel, Check } from '@mui/icons-material';
import { CircularProgress,  Popover } from '@mui/material';
import React, { useEffect, useState } from 'react'
import withAlert from '../../hoc/withAlert'

const TextInline = ({label,name, editId, value, updateInline,className,fontSize, fontWeight,AppAlert }) => {

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [inputValue,setInputValue] = useState(null);
    const [displayValue,setDisplayValue] = useState(null);
    const [savingInProgress,setSavingSataus] = useState(false);

    const popoverStatus = Boolean(anchorEl);
    const id = popoverStatus ? 'simple-popover' : undefined;

    const handleClose  = () =>{
        setAnchorEl(null);
    }

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleKeyUp = (e) =>{
        if(e.key === 'Enter') {
            e.preventDefault();
            update();
        }
    }
    
    const handleChange = (e) =>{
        setInputValue(e.target.value);
    }

    const update = () =>{
        if(inputValue === displayValue){
            AppAlert("No Changes Detect");
            handleClose();
            return false;
        }
        let data = {};
        data[name] = inputValue;
        data['id'] = editId;
        let payload = {
            data:data,
            type:"text",
        }
        setSavingSataus(true)
        updateInline(payload).then(res=>{
            handleClose();
            setDisplayValue(inputValue)
            AppAlert("Updated Successfully");
        }).catch(error => {
            AppAlert(error.msg,"error");
        }).finally(()=>{
            setSavingSataus(false)
        })
    }

    useEffect(()=>{
        //console.log(" I am here ");
        setInputValue(value);
        setDisplayValue(value);
    },[value])
    
    
    return (
        <>
            <Popover
                id={id}
                style={{backgroundColor: "transparent"}}
                open={popoverStatus}
                anchorEl={anchorEl}
                onClose={(handleClose)}
                anchorOrigin={{
                    //vertical: 'bottom',
                    //horizontal: 'left',
                    vertical: 'top',
                    horizontal: 'right',

                }}
                >
                <>
                    {savingInProgress && <CircularProgress/> }
                    {!savingInProgress && 
                        <div className="input-group">
                            {/*
                            <input type="text" value={inputValue} className="form-control" placeholder={label}
                                onChange={ (e)=> { handleChange(e)} } 
                                onKeyUp={ (e)=> { handleKeyUp(e)} } 
                                
                            /> */}
                            <textarea type="text" value={inputValue} className="form-control" placeholder={label}
                                onChange={ (e)=> { handleChange(e)} } 
                                onKeyUp={ (e)=> { handleKeyUp(e)} } 
                                
                            />
                            <div className="input-group-append">
                                <button className="btn btn-success" type="button" onClick={update}><Check/></button>
                                <button className="btn btn-danger" type="button" onClick={handleClose}><Cancel/></button>
                            </div>

                        </div>
                    }
                </>
            </Popover>
            {label &&
                <div className="row">
                    <div className="col-md-6">
                        <strong>{label} : </strong>
                    </div>
                    <div className={label?"col-md-6":"col-md-12"}>
                        {<span className="btn btn-link p-0 text-dark" style={{textDecorationStyle: "dotted", textUnderlineOffset: "3px"}} onClick={handleClick} > {displayValue}  </span> }
                    </div>
                </div>
            
            }
            {!label && <>
                {<span className="btn btn-link me-1 p-0 text-dark" style={{textDecorationStyle: "dotted", textUnderlineOffset: "3px"}} onClick={handleClick} > {displayValue}  </span> }
            </>}
            
        </>
    )
}
export default withAlert(TextInline);