import { Add, CheckCircle, SyncProblem } from '@mui/icons-material';
import { Dialog, DialogContent } from '@mui/material';
import React, { useState } from 'react'
import ConfirmDialog from '../../../components/ConfirmDialog';
import TextInline from '../../../components/InlineEdit/TextInline';
import RestoreDialog from '../../../components/RstoreDialog ';
import deviceTrackAdminServices from '../deviceTrackAdminServices';

function DeviceTrackAdmin({deviceId}) {
    const [dialogStatus,setDialogStatus] = useState(false);
    
    const [deviceTrackList,setDeviceTrackList] = useState(null);
    const getDeviceTrackList = () =>{
        deviceTrackAdminServices.getList(deviceId).then(res=>{
            setDeviceTrackList(res.data);
        })
    }

    const handleOpen = () => {
        getDeviceTrackList();
        setDialogStatus(true);
    }

    const handleClose = () => {
        setDeviceTrackList(null);
        setDialogStatus(false);
    }

    const addNewTrack = () =>{
        console.log("Add new track for "+deviceId)
        deviceTrackAdminServices.addNew({deviceId:deviceId}).then(res=>{
            console.log(res);
            getDeviceTrackList();
        }).catch(error=>{
            console.log(error)
        })
    }

    const remove = (id) =>{
        console.log("remove track ",id);
        deviceTrackAdminServices.remove(id).then(res=>{
            getDeviceTrackList();
        }).catch(error=>{
            console.log(error);
        })
    }

    return (
        <>
            <button className="btn btn-primary" onClick={handleOpen} >Tracks</button>
            <Dialog
                open={dialogStatus}
                keepMounted
                onClose={handleClose}
                fullWidth={true}
                maxWidth="md"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogContent>
                    <div className="row">
                        <div className="col-md-12">
                            <button className="btn btn-primary float-end" onClick={addNewTrack}><Add/> Add a Track</button>
                        </div>
                    </div>
                    {deviceTrackList &&
                    <div className="row">
                        <div className="col-md-12">
                            <div className="table-responsive">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Track #</th>
                                            <th>Message</th>
                                            <th>Audio Track</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {deviceTrackList.map( (track,i) =>
                                            <tr key={track.id} className={track.status == 0 && "table-danger"}>
                                                <td>{i+1}</td>
                                                <td>
                                                    <TextInline
                                                        name="trackTitle" editId={track.id} value={track.trackTitle} 
                                                        updateInline={deviceTrackAdminServices.updateInline} 
                                                    />
                                                </td>
                                                <td>
                                                    {track.url !== null &&
                                                        <div class="d-flex justify-content-between">
                                                            <audio controls>
                                                                <source src={track.url} />
                                                            </audio>
                                                            {track.syncStatus == 0 ? 
                                                                <span className="text-danger"><SyncProblem/></span>
                                                                :
                                                                <span className="text-success"><CheckCircle/></span>
                                                            }
                                                        </div>
                                                    }
                                                </td>
                                                <td>
                                                    {track.status === 1 ?
                                                        <ConfirmDialog id={track.id} confirm={remove} >Are you Sure You want to remove this track</ConfirmDialog>
                                                        :
                                                        <RestoreDialog id={track.id} confirm={remove} >Are you Sure You want to restore this track</RestoreDialog>
                                                    }
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    }
                    <div className="row">
                        <div className="col-md-12">
                            <button className="btn btn-outline-primary float-end" onClick={handleClose}>Close</button>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default DeviceTrackAdmin