import { SignalCellular0Bar, SignalCellular1Bar, SignalCellular2Bar, SignalCellular3Bar, SignalCellular4Bar, SignalCellularConnectedNoInternet0Bar } from '@mui/icons-material'
import { Tooltip } from '@mui/material'
import React, { useEffect, useState } from 'react'

function NteworkDetails({deviceState}) {

    const [NSI,setNSI] = useState(0)
    useEffect(()=>{
        let _ns = parseFloat(deviceState.N_S);

        if(_ns < 20){
            setNSI(0);
        }

        if(_ns >=20 &&_ns < 40){
            setNSI(1);
        }

        if(_ns >=40 &&_ns < 60){
            setNSI(2);
        }

        if(_ns >=60 &&_ns < 80){
            setNSI(3);
        }

        if(_ns >=80){
            setNSI(4);
        }
    },[])

    return (
    <>
        {deviceState.SIM !== undefined &&
            <Tooltip title={deviceState.N_S} placement="top" >
                <div className="card mt-2 p-1  bg-info">
                    <div class="d-flex justify-content-between">
                        <div>{deviceState.SIM}</div>
                        {NSI === 0 && <SignalCellularConnectedNoInternet0Bar/>}
                        {NSI === 1 && <SignalCellular1Bar />}
                        {NSI === 2 && <SignalCellular2Bar />}
                        {NSI === 3 && <SignalCellular3Bar />}
                        {NSI === 4 && <SignalCellular4Bar />}
                    </div>
                </div>
            </Tooltip>
        }
    </>
    
  )
}

export default NteworkDetails