import React from 'react'
import { Route, Routes } from 'react-router'
import SelectPortalPage from '../auth/pages/SelectPortalPage'
import Login from '../auth/pages/Login'
import Logout from '../auth/pages/Logout'
import Dashboard from '../dashboard/pages/Dashboard'
import BusinessListPage from '../app/business/pages/BusinessListPage'
import SystemMasterListPage from '../master/pages/SystemMasterListPage'
import UserListPage from '../users/pages/UserListPage'
import UserRolePage from '../users/pages/UserRolePage'
import DeviceAdminList from '../app/admin/pages/DeviceAdminList'
import SiteAdminList from '../app/admin/pages/SiteAdminList'
import TrackAdminList from '../app/admin/pages/TrackAdminList'
import DeviceSettingPage from '../app/admin/pages/DeviceSettingPage'
import DeviceClientList from '../app/client/device/pages/DeviceClientList'
import ReportPage from '../app/client/report/pages/ReportPage'
import AwsTTSPage from '../app/client/device/pages/AwsTTSPage'

export default function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={ <DeviceClientList /> } />
      <Route path="/auth/select-portal" element={ <SelectPortalPage /> } />
      <Route path="/auth/login" element={ <Login/> } />
      <Route path="/auth/logout" element={ <Logout/> } />
      <Route path="/system-master/list" element={ <SystemMasterListPage /> } />
      <Route path="/business/details" element={ <BusinessListPage /> } />
      <Route path="/user/list" element={ <UserListPage/> } />
      <Route path="/user/role" element={ <UserRolePage/> } />

      <Route path="/admin/device/list" element={ <DeviceAdminList/> } />
      <Route path="/admin/device/setting/:id" element={ <DeviceSettingPage/> } />
      <Route path="/admin/site/list" element={ <SiteAdminList/> } />
      <Route path="/admin/track/list" element={ <TrackAdminList /> } />



      <Route path="/my/device" element={ <DeviceClientList /> } />
      <Route path="/device/report" element={ <ReportPage /> } />

      <Route path="/tts/aws" element={ <AwsTTSPage /> } />

    </Routes>
  )
}
